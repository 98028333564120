<template>
  <div class="wrap">
    <div class="title">
      <div class="back" @click="back()">
        <img src="../assets/img/arrow.svg" alt="arrow" />
      </div>
      <div class="letter">隐私协议</div>
    </div>
    <div v-html="text" class="content-wrap"></div>
  </div>
</template>

<script>
import { getVerificode } from "../util/crypto";
export default {
  name: "policy",
  methods: {
    back() {
      this.$router.back();
      // 左上角回退箭头
    },
  },
  created() {
    this.$http({
      method: "post",
      url: "/api/Viewpage/index",
      data: {
        app_type: "qwjk",
        verificode: getVerificode(),
        type: "private_policy",
      },
    })
      .then((res) => {
        const data = res.data;
        this.text = data.data;
        console.log(this.text);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  data() {
    return {
      text: null,
    };
  },
};
</script>

<style lang="less" scoped>
body {
  background-color: #f7f7f9;
}
.wrap {
  width: 750px;
  height: 1334px;
  background-color: #ffffff;
  .title {
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 750px;
    height: 70px;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
    .back {
      position: absolute;
      top: 20px;
      left: 30px;
      img {
        width: 33px;
        height: 33px;
      }
    }
    .letter {
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #343638;
    }
  }
  .content-wrap {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 20px;
    p,
    span {
      font-size: 16px;
    }
  }
}
</style>
